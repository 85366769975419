import Vue from 'vue';
export const EventBus = new Vue();

export const Events = {
  SHOW_EDIT_DOMAIN_DIALOG: 'SHOW_EDIT_DOMAIN_DIALOG',
  SHOW_EDIT_DOMAIN_DIALOG_SHOW_ADVANCED_CONFIGURATION: 'SHOW_EDIT_DOMAIN_DIALOG_SHOW_ADVANCED_CONFIGURATION',
  SHOW_EDIT_DOMAIN_DIALOG_SHOW_SECURITY_SETTING: 'SHOW_EDIT_DOMAIN_DIALOG_SHOW_SECURITY_SETTING',
  SHOW_CACHE_STRATEGY: 'SHOW_CACHE_STRATEGY',
  SHOW_SHIELD_DETECTION: 'SHOW_SHIELD_DETECTION',
  SHOW_RATE_LIMITING: 'SHOW_RATE_LIMITING',
  SHOW_GEO_RESTRICTION: 'SHOW_GEO_RESTRICTION',
  SHOW_DIAGNOSE_DOMAIN_DIALOG: 'SHOW_DIAGNOSE_DOMAIN_DIALOG',
  SHOW_APPLY_RULE_DIALOG: 'SHOW_APPLY_RULE_DIALOG',
  SHOW_CONFIGURE_SSL_DIALOG: 'SHOW_CONFIGURE_SSL_DIALOG',
  SELECT_DOMAIN: 'SELECT_DOMAIN',
  SELECT_ALL_DOMAIN: 'SELECT_ALL_DOMAIN',
  DESELECT_DOMAIN: 'DESELECT_DOMAIN',
  DESELECT_ALL_DOMAIN: 'DESELECT_ALL_DOMAIN',
  CLEAR_CACHE_DOMAIN: 'CLEAR_CACHE_DOMAIN',
  DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERIFICATE',
  DOMAIN_TOOLTIP_MESSAGE: 'DOMAIN_TOOLTIP_MESSAGE',
  REPLACE_COMBINE_RULES: 'REPLACE_COMBINE_RULES',
  HANDLE_CLOSE_MODEL_APPLY_RULE: "HANDLE_CLOSE_MODEL_APPLY_RULE"
};